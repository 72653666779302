import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

import Participation from "@application/Components/participants/details/participation/Participation";
import TabsView from "@application/Components/participants/details/tabsView/TabsView";
import ParticipantProfileCard from "@application/Components/participants/ParticipantProfileCard";
import ParticipantRolesCard from "@application/Components/participants/ParticipantRolesCard";
import ContainerModalParticipant from "@application/Containers/ContainerModalParticipant";
import ParticipantController from "@application/Controllers/ParticipantController";
import HooksApp from "@application/Hooks";
import Title from "@infrastructure/components/interface/titles/Title";
import { type DtoProfile, useContextModule, useSnackBarHook, getPersonTitle } from "@key4-front-library/core";
import { EnumCustomFieldScope, type EnumParticipantStatusInvitation } from "@key4-front-library/core/Enums";
import { Box, Grid, Skeleton, Stack } from "@mui/material";

import { Providers } from "../../application/routes/index-pages";

const PageParticipantDetails = () => {
	const { sendSuccess, sendError } = useSnackBarHook();

	const { participantId: currentParticipantId } = useParams();
	const { client, event } = useContextModule();

	const { get } = HooksApp.ParticipantHook.useEntity();
	const { data: participant, isLoading, refetch } = get(currentParticipantId);

	const [isOpenParticipantModal, setIsOpenParticipantModal] = useState(false);

	const translations = {
		buttons: {
			edit: t("old.form.buttons.edit"),
			settings: t("old.form.buttons.settings"),
		},
		tabs2: {
			privileges: {
				label: t("old.registration.privileges.privilege.label"),
				costCenter: t("old.registration.privileges.costCenter.label"),
				rank: t("old.registration.privileges.rank.label"),
				noData: t("old.registration.privileges.privilege.noData"),
			},
			faculty: {
				label: t("old.registration.participantDetails.tabs2.faculty.title"),
			},
			mails: { label: t("old.registration.participantDetails.tabs2.mails") },
		},
		state: {
			created: t("old.registration.participantDetails.state.created"),
			updated: t("old.registration.participantDetails.state.updated"),
		},
		editionModal: {
			title: t("old.registration.participants.edition.title.modification"),
			returnMessages: {
				success_modification: t("old.registration.participants.edition.returnMessages.success_modification"),
			},
		},
	};

	const handleChangeProfile = async (participantId: string, profile: DtoProfile | null) => {
		await ParticipantController.assignProfile(client.id, event.id, participantId, profile?.id ?? null)
			.then((response) => {
				if (response) {
					sendSuccess(t("old.registration.participantDetails.profile.updated.success"));
				} else {
					sendError(t("old.registration.participantDetails.profile.updated.error"));
				}
			})
			.catch((responseError) => {
				sendError(responseError);
			})
			.finally(() => refetch());
	};

	const handleChangeStatus = async (participantId: string, status: EnumParticipantStatusInvitation) => {
		await ParticipantController.assignInvitationStatus(client.id, event.id, participantId, { invitationStatus: status })
			.then((response) => {
				if (response) {
					sendSuccess(t("old.registration.participantDetails.invitationStatus.updated.success"));
				} else {
					sendError(t("old.registration.participantDetails.invitationStatus.updated.error"));
				}
			})
			.catch((responseError) => {
				sendError(responseError);
			})
			.finally(() => refetch());
	};

	const handleModalParticipantCallbackAction = async () => refetch();

	if (isLoading) {
		return (
			<Stack>
				{[...Array(3).keys()].map((item) => (
					<Skeleton key={item} animation="wave" width={"xl"} height={40} />
				))}
			</Stack>
		);
	}

	if (participant && currentParticipantId) {
		const { roles } = participant;
		return (
			<Stack>
				<Title
					title={getPersonTitle(participant)}
					buttons={[
						{
							label: translations.buttons.edit,
							startIcon: "fa-pencil",
							handleClick: () => {
								setIsOpenParticipantModal(true);
							},
						},
					]}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12} md={9}>
						<Stack>
							<Box display={{ md: "none", xs: "inline-block" }} sx={{ mb: 2.5 }}>
								<ParticipantProfileCard participant={participant} onChangeProfile={handleChangeProfile} onChangeInvitationStatus={handleChangeStatus} />
							</Box>
							<Providers.CustomFields.Definition scope={[EnumCustomFieldScope.PARTICIPANT]}>
								<Participation participant={participant} />
							</Providers.CustomFields.Definition>
						</Stack>
					</Grid>

					<Grid item xs={12} md={3}>
						<Stack mt={6.5}>
							<Box display={{ xs: "none", md: "inline-block" }}>
								<ParticipantProfileCard participant={participant} onChangeProfile={handleChangeProfile} onChangeInvitationStatus={handleChangeStatus} />
							</Box>
							{roles?.length && (
								<Box>
									<ParticipantRolesCard roles={roles} />
								</Box>
							)}
						</Stack>
					</Grid>
				</Grid>

				<TabsView participantId={currentParticipantId} translations={translations} />

				<ContainerModalParticipant
					participantId={currentParticipantId}
					isOpen={isOpenParticipantModal}
					changeIsOpen={setIsOpenParticipantModal}
					callbackSubmit={handleModalParticipantCallbackAction}
				/>
			</Stack>
		);
	}
};

export default PageParticipantDetails;
