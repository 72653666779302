import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Menu from "@application/Configurations/menu.configuration";
import { BreadcrumbModule, EMenuPath, EnumCustomFieldScope, ErrorTemplateOld, NotificationProvider, OutletModule, Redirection } from "@key4-front-library/core";
import Layout from "@key4-front-library/core/Bo/Layout/Layout";
import ProvidersCore from "@key4-front-library/core/Providers";

import { TabEmailing } from "@presentation/emailing/TabEmailing";
import { TabMailTemplate } from "@presentation/mailTemplate/TabMailTemplate";
import { Pages, Providers } from "../routes/index-pages";

export const routes: Array<RouteObject> = [
	{
		path: EMenuPath.ROOT,
		children: [
			{
				path: EMenuPath.EMPTY,
				element: (
					<ProvidersCore.ProviderModule>
						<Redirection />
					</ProvidersCore.ProviderModule>
				),
			},
			{
				path: EMenuPath.AUTH,
				children: [
					{
						path: EMenuPath.LOGIN,
						element: <Navigate replace to={localStorage.getItem("redirectUri") ?? EMenuPath.EMPTY} />,
					},
					{
						path: EMenuPath.LOGOUT,
						element: <Navigate replace to={EMenuPath.ROOT} />,
					},
				],
			},
			{
				path: EMenuPath.BRANDS,
				element: <Pages.PagesMentions.PageBrands />,
			},
			{
				path: EMenuPath.TERMS_OF_SALE,
				element: <Pages.PagesMentions.PageTermsOfSale />,
			},
			{
				path: EMenuPath.LEGAL_NOTICE,
				element: <Pages.PagesMentions.PageLegalNotice />,
			},
			{
				path: EMenuPath.FORBIDDEN,
				element: <ErrorTemplateOld code={403} />,
			},
		],
	},
	{
		path: EMenuPath.CLIENT,
		children: [
			{
				path: EMenuPath.EVENT,
				element: (
					<ProvidersCore.ProviderModule>
						<OutletModule />
					</ProvidersCore.ProviderModule>
				),
				children: [
					{
						element: (
							<NotificationProvider>
								<Layout menu={Menu} breadcrumb={<BreadcrumbModule />} />
							</NotificationProvider>
						),
						children: [
							{
								path: EMenuPath.EMPTY,
								element: <Navigate replace to={EMenuPath.DASHBOARD} />,
							},
							{
								path: EMenuPath.DASHBOARD,
								element: <Pages.PageDashboard />,
							},
							{
								path: EMenuPath.PARTICIPANTS,
								children: [
									{
										path: EMenuPath.EMPTY,
										element: (
											<Providers.CustomFields.Definition scope={[EnumCustomFieldScope.PARTICIPANT]}>
												<Pages.PageParticipants />
											</Providers.CustomFields.Definition>
										),
									},
									{
										path: EMenuPath.PARTICIPANT,
										element: <Pages.PageParticipantDetails />,
									},
									{
										path: EMenuPath.BULKACTION,
										children: [
											{
												path: EMenuPath.STEP,
												element: <Pages.BulkActions.Participant />,
											},
										],
									},
								],
							},
							{
								path: EMenuPath.PRIVILEGES,
								element: <Pages.PagePrivileges />,
								children: [
									{
										path: EMenuPath.PRIVILEGES_ANALYTICS,
										element: <Pages.PagePrivilegesAnalytics />,
									},
									{
										path: EMenuPath.PRIVILEGES_MATRIX,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Pages.PagePrivilegesMatrix />,
											},
											{
												path: EMenuPath.PRIVILEGES_MATRIX_PRIVILEGE,
												element: <Pages.PagePrivilegeDetails />,
											},
										],
									},
								],
							},
							{
								path: EMenuPath.SETTINGS,
								element: <Pages.PageSettings />,

								children: [
									{
										path: EMenuPath.ROLES,
										element: <Pages.PageSettingsRoles />,
									},
									{
										path: EMenuPath.PROFILES,
										element: <Pages.PageSettingsProfiles />,
									},
									{
										path: EMenuPath.COST_CENTERS,
										element: <Pages.PageSettingsCostCenters />,
									},
									{
										path: EMenuPath.MAIL_TEMPLATES,
										element: <TabMailTemplate />,
									},
									{
										path: EMenuPath.EMAILING,
										element: <TabEmailing />,
									},
								],
							},
							{
								path: EMenuPath.USERS,
								element: <Pages.Users._ />,
							},
							{
								path: EMenuPath.FORM_EDITOR,
								element: <Pages.PageCFEditorCustomField />,
							},
						],
					},
				],
			},
			{
				path: EMenuPath.EMPTY,
				element: (
					<ProvidersCore.ProviderModule>
						<ErrorTemplateOld code={404} />
					</ProvidersCore.ProviderModule>
				),
			},
		],
	},
	{
		path: EMenuPath.WILDCARD,
		element: <ErrorTemplateOld code={404} />,
	},
];
